<template>
	<v-card
		elevation="0"
		:loading="loadingData"
		class="ma-0 pa-0"
	>
		<v-card-text class="pl-0 py-5">
			<v-row justify="space-between">
				<v-col cols="auto" class="py-0">
					<v-alert
						dense
						outlined
						border="left"
						:value="errorOn && !dialog"
						:type="errorType"
						class="ma-0 py-1"
					>
						{{ errorMessage }}
					</v-alert>
				</v-col>

				<v-col cols="auto" class="text-right py-0 mb-1">
					&nbsp;<br/>&nbsp;
				</v-col>
			</v-row>
		</v-card-text>
		
		<v-card-text class="pa-0 ma-0">
			<v-simple-table :height="userFormHeight - 40">
				<template v-slot:default>
				
					<v-row no-gutters class="ml-5">
						<v-col cols="10" sm="8" md="7" lg="5" xl="3">
							<v-text-field
								label="Username"
								v-model="userNameOld"
								readonly
							></v-text-field>
						</v-col>
						
						<v-col cols="auto" class="pt-3">
							<v-tooltip bottom v-if="isLoaded">
								<template v-slot:activator="{ on: onToolTip }">
									<v-btn v-on="onToolTip" icon color="info" @click="edit('user')">
										<v-icon>mdi-pencil-circle</v-icon>
									</v-btn>
								</template>
								<small>Update</small>
							</v-tooltip>
						</v-col>
					</v-row>
					
					<v-row no-gutters class="ml-5">
						<v-col cols="10" sm="8" md="7" lg="5" xl="3">
							<v-text-field
								label="Email"
								v-model="userEmailOld"
								readonly
							></v-text-field>
						</v-col>
						
						<v-col cols="auto" class="pt-3">
							<v-tooltip bottom v-if="isLoaded">
								<template v-slot:activator="{ on: onToolTip }">
									<v-btn v-on="onToolTip" icon color="info" @click="edit('email')">
										<v-icon>mdi-pencil-circle</v-icon>
									</v-btn>
								</template>
								<small>Update</small>
							</v-tooltip>
						</v-col>
					</v-row>
					
					<v-row no-gutters class="ml-5">
						<v-col cols="10" sm="8" md="7" lg="5" xl="3">
							<v-text-field
								label="Password"
								v-model="userPasswordOld"
								readonly
								type="password"
							></v-text-field>
						</v-col>
						
						<v-col cols="auto" class="pt-3">
							<v-tooltip bottom v-if="isLoaded">
								<template v-slot:activator="{ on: onToolTip }">
									<v-btn v-on="onToolTip" icon color="info" @click="edit('password')">
										<v-icon>mdi-pencil-circle</v-icon>
									</v-btn>
								</template>
								<small>Update</small>
							</v-tooltip>
						</v-col>
					</v-row>
				</template>					
			</v-simple-table>
		
			<v-dialog
				v-model="dialog"
				:fullscreen="$vuetify.breakpoint.xsOnly"
				max-width="400"
				:persistent="loadingUpdate"
				scrollable
				@click:outside="$refs.form.resetValidation();"
				@keydown.enter="verifyAccount"
			>
				<v-card
					color="primary"
					dark
					v-if="loadingUpdate"
				>
					<v-card-text class="pt-2">
						Saving...
						<v-progress-linear
							indeterminate
							color="white"
							class="mb-0"
						></v-progress-linear>
					</v-card-text>
				</v-card>
				
				<v-card v-else>
					<v-card-title class="headline primary white--text" primary-title>
						{{ dialogTitle }}
						
						<v-spacer></v-spacer>
						
						<v-btn icon
							@click="$refs.form.resetValidation(); dialog = false;"
							class="white--text"
						>
							<v-icon small dark>mdi-close</v-icon>
						</v-btn>
					</v-card-title>
					
					<v-card-text class="pt-5">
						<v-form ref="form">
							<v-text-field
								v-if="dialogForm == 'user'"
								label="Username"
								v-model="userName"
								:error="errorUser"
								:error-messages="errorUserMsg"
								:loading="loadingUser"
								:rules= "rulesUser"
								validate-on-blur
								@change= "uniqueUser()"
								@keyup= "uniqueUser()"
								@focus="errorUser = false; errorUserMsg = '';"
							></v-text-field>
							
							<v-text-field
								v-if="dialogForm == 'email'"
								label="Email"
								v-model="userEmail"
								:error="errorEmail"
								:error-messages="errorEmailMsg"
								:loading="loadingEmail"
								:rules= "rulesEmail"
								validate-on-blur
								@change= "uniqueEmail()"
								@keyup= "uniqueEmail()"
								@focus="errorEmail = false; errorEmailMsg = '';"
							></v-text-field>
							
							<v-text-field
								v-if="dialogForm == 'password'"
								label="New Password"
								v-model="userPassword"
								:append-icon="passwordNewInput ? 'mdi-eye' : 'mdi-eye-off'"
								hint= "Must be atleast 8 characters"
								:rules= "rulesPassword"
								validate-on-blur
								:type="passwordNewInput ? 'text' : 'password'"
								@click:append="passwordNewInput = !passwordNewInput"
							></v-text-field>
							
							<v-text-field
								label="Enter your password"
								v-model="verifyPassword"
								:append-icon="passwordInput ? 'mdi-eye' : 'mdi-eye-off'"
								:error="errorVerifiedPassword"
								:error-messages="errorVerifiedPasswordMsg"
								rulesPassword
								:loading="loadingPassword"
								:rules= "rulesPassword"
								validate-on-blur
								:type="passwordInput ? 'text' : 'password'"
								@click:append="passwordInput = !passwordInput"
								@focus="errorVerifiedPassword = false; errorVerifiedPasswordMsg = '';"
							></v-text-field>
						</v-form>
					</v-card-text>
					
					<v-card-actions class="px-auto">
						<v-spacer></v-spacer>
						<v-btn color="blue darken-1" text @click="dialog = !dialog">Cancel</v-btn>
						<v-btn color="blue darken-1" text @click="verifyAccount()">Update</v-btn>
					</v-card-actions>
				</v-card>
				
			</v-dialog>
			
		</v-card-text>
	</v-card>
</template>

<script>
import { CLEAR_ALERT } from '@/store/actions/user';

import {mask} from 'vue-the-mask';
import { mapGetters } from 'vuex';

var timeoutData = null;
var timeoutUser = null;
var timeoutEmail= null;
var timeoutClear= null;

export default {
	directives: {mask},
	data () {
		return {
			dialog					: false,
			dialogForm				: '',
			dialogTitle				: '',
			dialogValid				: false,
			
			//loading status
			isLoaded				: false,
			loadingData				: false,
			loadingUpdate			: false,
			
			loadingUser				: false,
			loadingEmail			: false,
			loadingPassword			: false,
			
			errorUser				: false,
			errorUserMsg			: '',
			errorEmail				: false,
			errorEmailMsg			: '',
			errorVerifiedPassword	: false,
			errorVerifiedPasswordMsg: '',
			
			verifyPassword			: '',
			verifyEmail				: false,
			passwordInput			: false,
			passwordNewInput		: false,
			
			userName				: '',
			userEmail				: '',
			userPassword			: '',
			
			userNameOld				: '',
			userEmailOld			: '',
			userPasswordOld			: '********',
			
			rulesUser: [
				v => !!v || 'Username is required',
				v => (v && v.length >= 3) || 'Must be atleast 3 characters',
			],
			rulesEmail: [
				v => !!v || 'E-mail is required',
				v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
			],
			rulesPassword: [
				v => !!v || 'Password is required',
				v => (v && v.length >= 8) || 'Must be atleast 8 characters',
			]
		}
	},
	methods: {
		getAccount () {
			if(this.loadingData && typeof source !== 'undefined')source.cancel();
			
			const CancelToken = this.$http.CancelToken;
			let source = CancelToken.source();
			
			this.$store.dispatch(CLEAR_ALERT);
			this.loadingData = true;
			this.isLoaded = false;
			
			clearTimeout(timeoutData);
				
			this.$http.get('account', { cancelToken: source.token })
			.then((resp) => {
				this.userName = this.userNameOld = resp.data.user;
				this.userEmail = this.userEmailOld = resp.data.email;
				
				this.isLoaded = true;
			})
			.catch(() => {
				timeoutData = setTimeout(() => {
					this.getAccount();
				}, 60000);
			})
			.finally(() => {
				this.loadingData = false;
			});
		},
		edit(val){
			this.dialogForm = val;
			this.setDialogTitle();
			this.verifyPassword = '';
			this.dialog = true;
			
			setTimeout(() => {
				if(typeof this.$refs.form !== 'undefined')this.$refs.form.resetValidation();
			}, 50);
		},
		update(field){
			this.$store.dispatch(CLEAR_ALERT);
			this.loadingUpdate = true;
			
			this.$http.put('account', {
				form	: this.dialogForm,
				field	: field,
			})
			.then(() => {
				if(this.dialogForm == 'user'){
					this.userNameOld = this.userName;
				}else if(this.dialogForm == 'email'){
					this.userEmailOld = this.userEmail;
				}
				this.dialog = false;
			})
			.finally(() => {
				clearTimeout(timeoutClear);
				timeoutClear = setTimeout(() => { this.loadingUpdate = false; }, 200);
			});
		},
		verifyAccount(){
			var field = '';
			if(this.dialogForm == 'user'){
				this.dialogValid = !this.errorUser && this.$refs.form.validate();
				field = this.userName;
			}else if(this.dialogForm == 'email'){
				this.dialogValid = !this.errorEmail && this.$refs.form.validate();
				field = this.userEmail;
			}else if(this.dialogForm == 'password'){
				this.dialogValid = this.$refs.form.validate();
				field = this.userPassword;
			}
			
			if(this.dialogValid){
				if( this.verifyPassword.length >= 8 ){
					this.loadingPassword = true;
					this.errorVerifiedPassword = false;
					this.errorVerifiedPasswordMsg = '';
					
					this.$http.get('account/verify', { params:{ password: this.verifyPassword } })
					.then((resp) => {
						if( typeof resp.data.success !== 'undefined' ){
							this.update(field);
						}else if( typeof resp.data.error !== 'undefined' ){
							this.errorVerifiedPassword = true;
							this.errorVerifiedPasswordMsg = resp.data.error;
						}
					})
					.catch(() => {
						this.errorVerifiedPassword = true;
						this.errorVerifiedPasswordMsg = 'Error, try again';
					})
					.finally(() => {
						this.loadingPassword = false;
					});
				}else{
					this.errorVerifiedPassword = true;
					this.errorVerifiedPasswordMsg = 'Invalid password';
				}
			}
		},
		uniqueUser(){
			clearTimeout(timeoutUser);
			if( this.userName.length > 2 ){
				
				timeoutUser = setTimeout(() => {
					this.loadingUser = true;
					
					this.$http.post('account/uniqueUser', { user: this.userName })
					.then((response) => {
						if( response.data.count == 0 ){
							this.errorUser= false;
							this.errorUserMsg= "";
						}else{
							this.errorUser= true;
							this.errorUserMsg= "Username already exist";
						}			
					}).catch(() =>{
						this.errorUser= true;
						this.errorUserMsg= "Verification error, try again";
					})
					.finally(() => {
						this.loadingUser = false;
					});
				}, 500);
			}
		},
		uniqueEmail(){
			clearTimeout(timeoutEmail);
			if( /.+@.+\..+/.test(this.userEmail) ){
				
				timeoutEmail = setTimeout(() => {
					this.loadingEmail = true;
					
					this.$http.post('account/uniqueEmail', { email: this.userEmail })
					.then((response) => {
						if( response.data.count == 0 ){
							this.errorEmail= false;
							this.errorEmailMsg= "";
							
							if(this.userEmail != this.userEmailOld){
								this.verifyEmail = true;
							}
						}else{
							this.errorEmail= true;
							this.errorEmailMsg= "Email already exist";
						}
					}).catch(() =>{
						this.errorEmail= true;
						this.errorEmailMsg= "Verification error, try again";
					})
					.finally(() => {
						this.loadingEmail = false;
					});
				}, 500);
			}
		},
		stringLength(text){
			return (typeof text === 'undefined' || text === null)? 0 : text.length;
		},
		setDialogTitle () {
			if(this.dialogForm == 'user'){
				this.dialogTitle = 'Update username';
			}else if(this.dialogForm == 'email'){
				this.dialogTitle = 'Update email';
			}else if(this.dialogForm == 'password'){
				this.dialogTitle = 'Update password';
			}
			
		},
	},
	computed:{
		...mapGetters(['userFormHeight', 'errorOn', 'errorType', 'errorMessage']),
	},
	created(){
		this.getAccount();
	},
	beforeDestroy(){
		clearTimeout(timeoutData);
		clearTimeout(timeoutClear);
		clearTimeout(timeoutUser);
		clearTimeout(timeoutEmail);
	},
}
</script>

<style scoped>
	.uppercase-input >>> input {
		text-transform: uppercase
    }
</style>